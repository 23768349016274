import logo from './logo.svg';
import './App.css';
import React from 'react'
import { auth } from './firebase';
import { signInWithGoogle } from './firebase';
import firebase from './firebase'

import './sass/style.react.scss'
import axios from 'axios'
import Layout from './Layout';
import SignUp from "./SignUp"
import Auth from './Auth';  



class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         currentUser: null
        };
      }

    unsubscribeFromAuth = null;
    

    componentDidMount() {
      
      this.unsubscribeFromAuth = auth.onAuthStateChanged(user => {
        this.setState({ currentUser: user });
      });

    
    }
  
    componentWillUnmount() {
      this.unsubscribeFromAuth();
    }

    login = () => {
     
      signInWithGoogle().then(user => {

        console.log(user)

        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        if (user.additionalUserInfo.isNewUser) {
          firebase.firestore().collection("users").doc(user.user.email).set({
            name: user.user.displayName,
            email: user.user.email,
            uid: user.user.uid,
            creationTime: user.user.metadata.creationTime,
            lastLoggedIn: dateTime,
            photoURL: user.user.photoURL,
            watchList: ["AAPL", "TSLA"]
          
          }, {merge: true})

        }

        else {

          firebase.firestore().collection("users").doc(user.user.email).set({
            lastLoggedIn: dateTime,
            lastSignInTime: user.user.metadata.lastSignInTime
          
          }, {merge: true})

        }

        // Add a new document in collection "cities"


        /*firebase.firestore().collection('users').doc(``).set({
           name: user.user.displayName,
           email: user.user.email,
           uid: user.user.uid,
           creationTime: user.user.creationTime,
           watchList: []

          });*/
         
        

      });


    }

    signout = () => {
      auth.signOut()
    }
 

  render() {

    
    console.log(this.state.currentUser)
    return this.state.currentUser === null ? <SignUp login = {this.login}/> : <Layout signout = {this.signout} name = {this.state.currentUser}/>
    
  }

}



export default App;
