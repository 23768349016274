import React from 'react'
class Header extends React.Component {

  changeToScreener = () => {
    this.props.screener()
  }

  changeToWatchlist = () => {
    this.props.watchlist()
  }
   
  
    render() { 
        return ( <div className="header-bottom">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Header Menu Wrapper*/}
          <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            {/*begin::Header Menu*/}
            <div id="kt_header_menu" className="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
              {/*begin::Header Nav*/}
              <ul className="menu-nav">
                <li onClick = {() => this.changeToScreener()} className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
                  <a href="javascript:;" className="menu-link menu-toggle">
                    <span className="menu-text">Screener</span>
                    <span className="menu-desc">Find Stocks To Invest</span>
                    <i className="menu-arrow" />
                  </a>
                </li>
                <li onClick = {() => this.changeToWatchlist()} className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
                  <a href="javascript:;" className="menu-link menu-toggle">
                    <span className="menu-text">Watchlist</span>
                    <span className="menu-desc">Manage your favorites</span>
                    <i className="menu-arrow" />
                  </a>
                </li>
                <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
                  <a href="https://itradely.com/stock-research" target="_blank" rel="noopener noreferrer" className="menu-link menu-toggle">
                    <span className="menu-text">Research</span>
                    <span className="menu-desc">Dig Deep into the companies</span>
                    <i className="menu-arrow" />
                  </a>
                </li>
                <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
                  <span href="#" className="menu-link menu-toggle">
                    <span className="menu-text">Premium Picks</span>
                    <span className="menu-desc">Coming Soon!</span>
                    <i className="menu-arrow" />
                  </span>
                </li>
               
              </ul>
              {/*end::Header Nav*/}
            </div>
            {/*end::Header Menu*/}
          </div>
          {/*end::Header Menu Wrapper*/}
        </div>
        {/*end::Container*/}
      </div>
       );
    }
}
 
export default Header;