import firebase from 'firebase'
import 'firebase/auth';
const firebaseConfig = {
  apiKey: "AIzaSyCAxmqCNuGHATTU7siY0kFOh62N1t5neIA",
  authDomain: "itradely-5d680.firebaseapp.com",
  databaseURL: "itradely-5d680.firebaseio.com",
  projectId: "itradely-5d680",
  storageBucket: "itradely-5d680.appspot.com",
  messagingSenderId: "267075196958",
  appId: "1:267075196958:web:de3d8565afc57d078d760f",
  measurementId: "G-CWWR0VD18G"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);


  
 
export default firebase;